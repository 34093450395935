/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";
import cookiesManipulator from "services/browserStorage/cookies";
import locationService from "services/utils/locationService";

const uri = "auths";

// Need This
export const loginUser = createAsyncThunk(
  `${uri}/loginUser`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/login`, "POST", {
        ...requestBody,
      });
      if (response.code === "success") {
        await cookiesManipulator.setAuth(response.data);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const logoutUser = createAsyncThunk(
  `${uri}/logoutUser`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/logout`, "PUT");
      if (response.code === "success") {
        await cookiesManipulator.removeAuth();
        return response.message;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const getUserLocation = createAsyncThunk(
  `${uri}/getUserLocation`,
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      const location = await locationService.getUserLocation();

      return location;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
