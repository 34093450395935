/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "store/slices/authSlice";
import fileSlice from "store/slices/fileSlice";
import trainingsSlice from "store/slices/trainingsSlice";
import contactSlice from "store/slices/contactSlice";

const store = configureStore({
  reducer: {
    auths: authSlice,
    files: fileSlice,
    trainings: trainingsSlice,
    contact: contactSlice,
  },
});
export default store;
