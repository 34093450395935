/* eslint-disable no-lone-blocks */
import axios from "axios";

import cookiesManipulator from "services/browserStorage/cookies";
import { readFromLocalStorage } from "services/browserStorage/localStorage";
import detectPlatform from "services/utils/detectPlatform";

const API_URL = process.env.REACT_APP_API_URL;

const headers = {
  "x-version": process.env.REACT_APP_VERSION.toString().replaceAll(".", ""),
  "x-origin": "2",
  "x-key": process.env.REACT_APP_API_KEY, // dev

  // "x-key": "eecc2f7ce69040d9844d52bdb46cf167bdc878e8292d40aaaadea1f37db4e465", // dev
  // "x-key": "4efc1a9ea64d44e6b54d50ee81064ce1ce49ba2d6bc6494b8d0d4dd9102320d5",
  // "x-key": "659df744b89f46569e137d12e432206ddeb18cf812934e2dae17c489910c478b", // stage,
};

const platformMap = {
  web: 1,
  ios: 2,
  android: 3,
};

const requestApi = async (resourcePath, method, params) => {
  // const url = `/${resourcePath}`; // for mirage
  const url = `${API_URL}/${resourcePath}`;

  const platformString = await detectPlatform();
  const platformNumber = platformMap[platformString] || 0;
  headers["x-platform"] = 1;

  headers["x-device-id"] = await readFromLocalStorage("identity");

  headers["x-auth"] = await cookiesManipulator.getAuth().token;

  if (resourcePath === "/auths/login") {
    delete headers["x-auth"];
  }
  let response;
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    response = await axios({ url, method, data: params, headers });

    if (response.data.code === "authn_fail") {
      await cookiesManipulator.removeAuth();
      localStorage.clear();
      window.location.href = "/";
    }
    return response.data;
  }
  response = await axios({ url, method, headers });
  if (response.data.code === "authn_fail") {
    await cookiesManipulator.removeAuth();
    localStorage.clear();
    window.location.href = "/";
  }
  return response.data;
};

export default requestApi;
