const detectPlatform = () => {
  let Name = "Unknown OS";
  if (navigator.userAgent.indexOf("Win") !== -1) Name = "web";
  if (navigator.userAgent.indexOf("Mac") !== -1) Name = "web";
  if (navigator.userAgent.indexOf("Linux") !== -1) Name = "web";
  if (navigator.userAgent.indexOf("Android") !== -1) Name = "android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) Name = "ios";
  if (navigator.userAgent.indexOf("Macintosh") !== -1) Name = "web";
  return Name;
};

export const detectIOS = () => {
  const { userAgent } = navigator;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  // if (browserName === "chrome" && detectPlatform() === "ios") {
  const externalSpeechRecognitionPlatforms = ["ios", "ipados"];

  if (externalSpeechRecognitionPlatforms.includes(detectPlatform())) {
    return true;
  }
  return false;
};

export default detectPlatform;
