import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import LinearProgressBar from "@mui/material/LinearProgress";
import { CircularProgress } from "@mui/material";

export default function Loader({ toggle, type }) {
  const [showLoader, setShowLoader] = React.useState(true);

  const progressBar = {
    linear: <LinearProgressBar />,
    circular: <CircularProgress />,
  };

  React.useEffect(() => {
    setShowLoader(toggle);
  }, [toggle]);

  return showLoader ? (
    <Box sx={{ width: "100%", background: "red" }} variant="success">
      {progressBar[type]}
    </Box>
  ) : null;
}

Loader.defaultProps = {
  type: "linear",
  toggle: true,
};

Loader.propTypes = {
  type: PropTypes.oneOf(["linear"]),
  toggle: PropTypes.bool,
};
