/* eslint-disable import/no-unresolved */
/** 
  All of the routes for the kai App are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  layouts
import { lazy, Suspense } from "react";
import ErrorBoundary from "components/ErrorBoundary";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import { useLocation, Navigate } from "react-router-dom";
import cookiesManipulator from "services/browserStorage/cookies";
import Loader from "components/Loader/Loader";
import { Collections, Home } from "@mui/icons-material";

const Make = lazy(() => import("containers/trainings/Make"));
const Model = lazy(() => import("containers/trainings/Model"));
const Variant = lazy(() => import("containers/trainings/Variant"));
const Part = lazy(() => import("containers/trainings/Part"));
const Upload = lazy(() => import("containers/files/Upload"));
const Review = lazy(() => import("containers/files/Review"));
const History = lazy(() => import("containers/files/History"));

function LazyMake(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Make {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyModel(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Model {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyVariant(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Variant {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyPart(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Part {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyUpload(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Upload {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyReview(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Review {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
function LazyHistory(props) {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <History {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

const routes = [
  {
    type: "collapse",
    showOnSideNav: true,
    name: "Home",
    key: "make",
    icon: <Home />,
    route: `/make`,
    layout: "trainings",
    component: <LazyMake />,
  },
  {
    type: "collapse",
    name: "model",
    key: "model",
    icon: <Icon fontSize="small">model</Icon>,
    route: `/make/:makeId/model`,
    layout: "trainings",
    component: <LazyModel />,
  },
  {
    type: "collapse",
    name: "variant",
    key: "variant",
    icon: <Icon fontSize="small">variant</Icon>,
    route: `/make/:makeId/model/:modelId/variant`,
    layout: "trainings",
    component: <LazyVariant />,
  },
  {
    type: "collapse",
    name: "part",
    key: "part",
    icon: <Icon fontSize="small">part</Icon>,
    route: `/make/:makeId/model/:modelId/variant/:variantId/part`,
    layout: "trainings",
    component: <LazyPart />,
  },
  {
    type: "collapse",
    name: "upload",
    key: "upload",
    icon: <Icon fontSize="small">part</Icon>,
    route: `/feature/:featureId/:partName/upload`,
    layout: "files",
    component: <LazyUpload />,
  },

  {
    type: "collapse",
    name: "Review",
    key: "review",
    icon: <Collections />,
    route: `/feature/:featureId/:partName/review`,
    layout: "files",
    component: <LazyReview />,
  },
  {
    type: "collapse",
    showOnSideNav: true,
    name: "History",
    key: "history",
    icon: <Collections />,
    route: `/history`,
    layout: "files",
    component: <LazyHistory />,
  },
];

export function RequireAuth({ children }) {
  const authed = !!cookiesManipulator.getAuth().token;
  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/auth/login" replace state={{ path: location.pathname }} />
  );
}
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default routes;
