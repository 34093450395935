/* eslint-disable no-alert */
import { Routes, Route, Navigate } from "react-router-dom";
import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import getBrowserFingerprint from "get-browser-fingerprint";
import PWAPrompt from "react-ios-pwa-prompt";

import themeLight from "assets/themeLight";
import routes, { RequireAuth } from "routes/routes";
import LoginContainer from "containers/auths/Login";
import cookiesManipulator from "services/browserStorage/cookies";

import postIndexDBdata from "services/utils/isolatedFunctions";
import { writeToLocalStorage, readFromLocalStorage } from "services/browserStorage/localStorage";

import { useSelector } from "react-redux";
import { useWorker } from "@koale/useworker";
import Dispatcher from "store/Dispatcher";
import { getUserLocation } from "store/actions/auths";
import Alerts from "components/Alerts/Alerts";
import STATUSES from "data/constants/STATUSES";
import { clearAuthErrorState } from "store/slices/authSlice";

function RoutesContainer({ children, status, errorMessage, handleCloseError }) {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  const appThemeLight = createTheme(themeLight);

  return (
    <ThemeProvider theme={appThemeLight}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          padding: "0",
          height: innerHeight,
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Alerts
          alertType="error"
          toggle={status === STATUSES.ERROR}
          content={errorMessage}
          handleCloseError={handleCloseError}
        />
        <CssBaseline />
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
        />
        <Routes>{children}</Routes>
      </Container>
    </ThemeProvider>
  );
}

export const indexedDBConfigs = { db: "trainings", storeName: "files" };

export default function App() {
  const { session, location, status, errorMessage } = useSelector((state) => state.auths);
  // Check if site's storage has been marked as persistent
  const clearError = () => {
    Dispatcher(clearAuthErrorState, {}, false);
  };

  const saveIdentity = async () => {
    const identifier = await getBrowserFingerprint({
      enableWebgl: true,
    });
    writeToLocalStorage("identity", identifier.toString());
    return identifier.toString();
  };

  useEffect(() => {
    async function requestPersistentStorage() {
      if (navigator.storage && navigator.storage.persist) {
        const isPersisted = await navigator.storage.persist();
        console.log(`Persisted storage granted: ${isPersisted}`);
      }
    }

    requestPersistentStorage();
    Dispatcher(getUserLocation, {}, false);
  }, []);

  useEffect(() => {
    saveIdentity();
  }, [readFromLocalStorage("identity")]);

  const [postIndexDBdataWorker, controller] = useWorker(postIndexDBdata, { autoTerminate: false });

  const workerExecution = async () => {
    const identity = readFromLocalStorage("identity");
    const token = await cookiesManipulator.getAuth().token;

    if (token) {
      try {
        await postIndexDBdataWorker({
          db: indexedDBConfigs.db,
          storeName: indexedDBConfigs.storeName,
          identity,
          token,
          location,
          REACT_APP_API_URL: process.env.REACT_APP_API_URL,
          REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
          REACT_APP_VERSION: process.env.REACT_APP_VERSION.toString().replaceAll(".", ""),
        });
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  };
  useEffect(() => {
    if (location.latitude) workerExecution();
  }, [location.latitude]);

  useEffect(() => {
    const channel = new BroadcastChannel("startPostingTrainingData");

    channel.onmessage = async () => {
      if (controller.status !== "RUNNING" && controller.status !== "ERROR" && location.latitude)
        workerExecution();
    };

    return () => channel.close();
  }, [controller.status, location.latitude]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.layout + route.route}
            element={
              route.layout === "auth" ? (
                route.component
              ) : (
                <RequireAuth>{route.component}</RequireAuth>
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <RoutesContainer status={status} errorMessage={errorMessage} handleCloseError={clearError}>
      ;{getRoutes(routes)}
      ;
      <Route
        path="/auths/login"
        element={session.token ? <Navigate to="/trainings/make" /> : <LoginContainer />}
      />
      <Route path="*" element={<Navigate to="/auths/login" />} />
    </RoutesContainer>
  );
}
