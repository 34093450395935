/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";

export const postQuery = createAsyncThunk(
  "contact/postQuery",
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().contact;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const body = { text: requestBody.query };
    try {
      const response = await requestApi("contact", "POST", body);
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  requestState: null,
  errorMessage: null,
  query: "",
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postQuery.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postQuery.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(postQuery.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      });
  },
});

// Action creators are generated for each case reducer function
// export const {} = contactSlice.actions;

export default contactSlice.reducer;
