import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUser, logoutUser } from "store/actions/auths";
import { clearAuthErrorState } from "store/slices/authSlice";
import Dispatcher from "store/Dispatcher";
import LoginView from "views/auths/Login";

function Auth() {
  const { status, errorMessage } = useSelector((state) => state.auths);
  const navigateTo = useNavigate();

  const clearError = () => {
    Dispatcher(clearAuthErrorState, {}, false);
  };

  const onLogin = async (requestBody) => {
    clearError();
    const response = await Dispatcher(loginUser, requestBody);
    if (response && typeof response !== "string") {
      navigateTo("/trainings/make");
    }
  };
  const onLogout = async () => {
    clearError();
    const response = await Dispatcher(logoutUser, { active: 0 });
    if (response && typeof response !== "string") {
      navigateTo("/trainings/make");
    }
  };

  return (
    <LoginView
      {...{
        status,
        errorMessage,
        clearError,
        onLogin,
        onLogout,
      }}
    />
  );
}

export default Auth;
