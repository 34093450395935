/* eslint-disable no-constructor-return */
/* eslint-disable no-case-declarations */

let dbInstance = null;
let isDBInitialized = false;

class Database {
  constructor(databaseName, storeName) {
    if (!dbInstance) {
      this.databaseName = databaseName;
      this.storeName = storeName;
      this.db = null;
      dbInstance = this;
    }

    return dbInstance;
  }

  initialize() {
    return new Promise((resolve, reject) => {
      if (isDBInitialized && this.db) {
        resolve(this.db);
      } else {
        const request = indexedDB.open(this.databaseName);

        request.onerror = (event) => {
          reject(event.target.errorCode);
        };

        request.onupgradeneeded = (event) => {
          this.db = event.target.result;
          const store = this.db.createObjectStore(this.storeName, {
            keyPath: "id",
            autoIncrement: true,
          });
          store.createIndex("uuid", "uuid", { unique: true });
        };

        request.onsuccess = (event) => {
          this.db = event.target.result;
          isDBInitialized = true;
          resolve(this.db);
        };
      }
    });
  }

  close() {
    if (this.db) {
      this.db.close();
      this.db = null;
      isDBInitialized = false;
    }
  }
}

const db = new Database("trainings", "files"); // Create a singleton instance of the Database class

export default function indexDB(databaseName, storeName, operationType, data, key) {
  return new Promise((resolve, reject) => {
    db.initialize()
      .then((database) => {
        const transaction = database.transaction(storeName, "readwrite");
        const store = transaction.objectStore(storeName);

        switch (operationType) {
          case "create":
            const addRequest = store.add(data);
            addRequest.onsuccess = () => {
              resolve(addRequest.result);
            };
            addRequest.onerror = (e) => {
              reject(e.target.error);
            };
            break;

          case "read":
            const readRequest = store.index("uuid").get(key);
            readRequest.onsuccess = () => {
              resolve(readRequest.result);
            };
            readRequest.onerror = (e) => {
              reject(e.target.error);
            };
            break;

          case "readAll":
            const readAllRequest = store.getAll();
            readAllRequest.onsuccess = () => {
              resolve(readAllRequest.result);
            };
            readAllRequest.onerror = (e) => {
              reject(e.target.error);
            };
            break;

          case "update":
            const updateRequest = store.get(key);
            updateRequest.onsuccess = () => {
              const dataToUpdate = updateRequest.result;
              const updatedData = { ...dataToUpdate, ...data };
              const putRequest = store.put(updatedData, key);
              putRequest.onsuccess = () => {
                resolve(updatedData);
              };
              putRequest.onerror = (e) => {
                reject(e.target.error);
              };
            };
            updateRequest.onerror = (e) => {
              reject(e.target.error);
            };
            break;
          case "count":
            const countRequest = store.count();
            countRequest.onsuccess = () => {
              resolve(countRequest.result);
            };
            countRequest.onerror = (e) => {
              console.error(`Error in "count" operation:`, e.target.error);
              reject(e.target.error);
            };
            break;
          case "delete":
            const deleteRequest = store.index("uuid").getKey(key);
            deleteRequest.onsuccess = () => {
              const deleteKey = deleteRequest.result;
              const deleteDataRequest = store.delete(deleteKey);
              deleteDataRequest.onsuccess = () => {
                resolve();
              };
              deleteDataRequest.onerror = (e) => {
                reject(e.target.error);
              };
            };
            deleteRequest.onerror = (e) => {
              reject(e.target.error);
            };
            break;

          case "clearAll":
            const clearRequest = store.clear();
            clearRequest.onsuccess = () => {
              resolve(clearRequest.result);
            };
            clearRequest.onerror = (e) => {
              console.error(`Error in "clearAll" operation:`, e.target.error);
              reject(e.target.error);
            };
            break;

          default:
            reject(new Error("Invalid operation type."));
        }

        transaction.oncomplete = () => {
          db.close();
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}
