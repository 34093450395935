const locationService = {
  getUserLocation: () =>
    new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(new Error("Failed to retrieve user location."));
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    }),
};

export default locationService;
