/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";

import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { indexedDBConfigs } from "App";
import indexDB from "services/browserStorage/indexDB";
import { updateIndexQueueLength } from "store/slices/fileSlice";

const uri = "trainings";
// Need this
export const getFiles = createAsyncThunk(
  `${uri}/getFiles`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().files;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      // const response = await requestApi(`${uri}?user_id=${requestBody}`, "GET");
      const response = await requestApi(
        `${uri}/review?feature_id=${requestBody.featureId}&content_type_id=2`,
        "GET"
      );

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);
// Need this
export const saveImageToIndexDB = createAsyncThunk(
  `${uri}/saveImageToIndexDB`,
  async (requestBody, { getState, requestId, rejectWithValue, dispatch }) => {
    const { currentRequestId, status } = getState().files;

    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const uuid = uuidv4().replace(/-/g, "");

    try {
      const { db, storeName } = indexedDBConfigs;
      const file_id = await indexDB(db, storeName, "create", {
        image_buff: requestBody.buffer,
        featureId: requestBody.featureId,
        uuid,
      });

      // const data = await indexDB(db, storeName, "readAll");
      // dispatch(updateIndexQueueLength(data.length));

      const response = { data: { file_id }, code: "success" };
      if (response.code === "success") {
        const channel = new BroadcastChannel("startPostingTrainingData");
        channel.postMessage("initiate the posting training data");
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);

export const postFile = createAsyncThunk(
  `${uri}/postFile`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().files;

    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    try {
      const response = await requestApi(`${uri}`, "POST", requestBody);

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);

export const deleteFile = createAsyncThunk(
  `${uri}/deleteFile`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().files;
    // if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
    //   return null;
    // }

    try {
      const response = await requestApi(`${uri}/${requestBody.fileId}`, "PUT", {
        active: 0,
      });

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);

export const getHistory = createAsyncThunk(
  `${uri}/getHistory`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState().files;
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/history`, "GET");

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      alert(err);
      throw err;
    }
  }
);
