/* eslint-disable consistent-return */
import * as React from "react";
import { Alert, Collapse, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function Alerts({ alertType, toggle, content, handleCloseError, duration }) {
  const [showAlert, setShowAlert] = React.useState(toggle);

  React.useEffect(() => {
    setShowAlert(toggle);
  }, [toggle]);

  const handleClose = () => {
    handleCloseError();
  };

  React.useEffect(() => {
    if (toggle === true) {
      const timer = setTimeout(() => {
        handleClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [toggle]);

  return (
    <Collapse
      in={showAlert}
      timeout={300}
      sx={{ width: "100%", display: toggle ? "block" : "none" }}
    >
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity={alertType} onClose={handleClose} sx={{ borderRadius: 0 }}>
          {content}
        </Alert>
      </Stack>
    </Collapse>
  );
}
Alerts.defaultProps = {
  content: "You have encountered an error",
  toggle: false,
  alertType: "error",
  duration: 3000,
};

Alerts.propTypes = {
  content: PropTypes.string,
  toggle: PropTypes.bool,
  duration: PropTypes.number,
  alertType: PropTypes.oneOf(["error", "warning", "info", "success"]),
  handleCloseError: PropTypes.func.isRequired,
};
