/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";
import STATUSES from "data/constants/STATUSES";

import {
  fetchMake,
  fetchModel,
  fetchVariant,
  fetchPart,
  fetchFeature,
} from "store/actions/trainings";

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  makes: [],
  models: [],
  variants: [],
  parts: [],
  feature: {},
  selectedMake: null,
  selectedModel: null,
  selectedVariant: null,
  selectedPart: null,
  selectedFeature: null,
};

export const authSlice = createSlice({
  name: "trainings",
  initialState,
  reducers: {
    clearTrainingsErrorState(state) {
      state.status = STATUSES.IDLE;
    },
    setSelectedMake(state, action) {
      state.selectedMake = action.payload;
    },
    setSelectedModel(state, action) {
      state.selectedModel = action.payload;
    },
    setSelectedVariant(state, action) {
      state.selectedVariant = action.payload;
    },
    setSelectedPart(state, action) {
      state.selectedPart = action.payload;
    },
    setSelectedFeature(state, action) {
      state.selectedFeature = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMake.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchMake.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.makes = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchMake.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(fetchModel.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchModel.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.models = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchModel.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(fetchVariant.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchVariant.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.variants = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchVariant.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(fetchPart.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.parts = [];
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchPart.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.parts = action.payload.map((part) => ({
            feature_id: part.feature_id,
            part_id: part.part_id,
            model: part.model,
            part: part.part,
          }));
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchPart.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(fetchFeature.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchFeature.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { stats } = action.payload;

          state.status = STATUSES.IDLE;
          [state.feature] = stats;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchFeature.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearTrainingsErrorState,
  setSelectedMake,
  setSelectedModel,
  setSelectedVariant,
  setSelectedPart,
  setSelectedFeature,
} = authSlice.actions;

export default authSlice.reducer;
