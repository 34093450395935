/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";

import cookiesManipulator from "services/browserStorage/cookies";

// import {  } from "store/actions/files";
import {
  getFiles,
  saveImageToIndexDB,
  deleteFile,
  postFile,
  getHistory,
} from "store/actions/files";

// Remove some props

const initialState = {
  currentRequestId: undefined,
  currentFileBuffer: null,
  status: STATUSES.IDLE,
  inference_count: cookiesManipulator.getAuth().inference_count,
  trainingsHistory: [],
  files: [],
  trainingFiles: [],
  trainingsCount: 0,
  fileUploadStats: null,
  selectedFile: null,
  errorMessage: null,
  indexQueueLength: 0,
};

export const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileUploadStats(state, action) {
      state.fileUploadStats = action.payload;
    },
    setCurrentFileBuffer(state, action) {
      state.currentFileBuffer = action.payload;
    },
    clearFileErrorState(state) {
      state.status = STATUSES.IDLE;
    },
    updateStatusToIdleState(state) {
      state.status = STATUSES.IDLE;
    },
    clearFiles(state) {
      state.files = [];
    },
    setSelectedFile(state, action) {
      state.selectedFile = action.payload;
    },
    setErrorState(state, action) {
      state.errorMessage = action.payload;
      state.status = STATUSES.ERROR;
    },
    updateIndexQueueLength(state, action) {
      state.indexQueueLength = action.payload;
    },
    updateInferenceCount(state) {
      const { inference_count } = cookiesManipulator.getAuth();
      cookiesManipulator.updateValue("inference_count", inference_count + 1);
      state.inference_count = inference_count + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
          state.trainingFiles = [];
        }
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        const { requestId } = action.meta;

        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { trainings } = action.payload;

          state.trainingFiles = trainings.map((training) => ({
            id: training.id,
            content: training.content,
          }));
          state.trainingsCount = trainings.length;
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getFiles.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(saveImageToIndexDB.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(saveImageToIndexDB.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { file } = action.payload;
          state.selectedFile = file;
          state.currentFileBuffer = null;
          state.files = [...current(state.files), file];
          state.currentRequestId = undefined;
          state.status = STATUSES.IDLE;
        }
      })
      .addCase(saveImageToIndexDB.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(postFile.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(postFile.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;

          state.currentRequestId = undefined;
        }
      })
      .addCase(postFile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })

      .addCase(deleteFile.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        if (state.status === STATUSES.LOADING) {
          const { training } = action.payload;
          state.trainingFiles = [...state.trainingFiles.filter((item) => item.id !== training.id)];
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteFile.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getHistory.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { history } = action.payload;

          state.trainingsHistory = history;

          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getHistory.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setFileUploadStats,
  setSelectedFile,
  setCurrentFileBuffer,
  clearFileErrorState,
  updateIndexQueueLength,
  clearFiles,
  updateStatusToIdleState,
  setErrorState,
} = fileSlice.actions;

export default fileSlice.reducer;
