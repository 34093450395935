/* eslint-disable no-new-func */
/* eslint-disable no-case-declarations */
/* eslint-disable no-await-in-loop */
const postIndexDBdata = new Function(
  `configs`,
  `
  return (async (configs) => {
    if(!configs){
      return null
    }
    const {db, storeName, identity, token, REACT_APP_API_URL, REACT_APP_API_KEY, REACT_APP_VERSION, location } = configs;

    function indexDBNew(databaseName, storeName1, operationType, data, key) {
      return new Promise((resolve, reject) => {
        // Open a connection to the database
        const request = indexedDB.open(databaseName);

        // Handle database errors
        request.onerror = (event) => {
          console.error("Database error:", event.target.errorCode);
          reject(event.target.errorCode);
        };

        // Handle database upgrades
        request.onupgradeneeded = (event) => {
          const db1 = event.target.result;
          const store = db1.createObjectStore(storeName1, { keyPath: "id", autoIncrement: true });
          store.createIndex("uuid", "uuid", { unique: true });
        };

        // Handle database success
        request.onsuccess = (event) => {
          const db1 = event.target.result;
          const transaction = db1.transaction(storeName1, "readwrite");
          const store = transaction.objectStore(storeName1);

          switch (operationType) {
            case "readAll":
              const readAllRequest = store.getAll();
              readAllRequest.onsuccess = () => {
                resolve(readAllRequest.result);
              };
              readAllRequest.onerror = (e) => {
                console.error(\`Error in "readAll" operation:\`, e.target.error);
                reject(e.target.error);
              };
              break;

            case "count":
              const countRequest = store.count();
              countRequest.onsuccess = () => {
                resolve(countRequest.result);
              };
              countRequest.onerror = (e) => {
                console.error(\`Error in "count" operation:\`, e.target.error);
                reject(e.target.error);
              };
              break;

            case "delete":
              const deleteRequest = store.index("uuid").getKey(key);
              deleteRequest.onsuccess = () => {
                const deleteKey = deleteRequest.result;
                const deleteDataRequest = store.delete(deleteKey);
                deleteDataRequest.onsuccess = () => {
                  resolve();
                };
                deleteDataRequest.onerror = (e) => {
                  console.error(\`Error in "delete" operation:\`, e.target.error);
                  reject(e.target.error);
                };
              };
              deleteRequest.onerror = (e) => {
                console.error(\`Error in "delete" operation:\`, e.target.error);
                reject(e.target.error);
              };
              break;

            default:
              console.error(\`Invalid operation type: \${operationType}\`);
              reject(new Error("Invalid operation type."));
          }

          transaction.oncomplete = () => {
            db1.close();
          };
        };
      });
    }

  let channel = new BroadcastChannel('indexDBUpdates');

async function fetchIndexDB() {
  const data = await indexDBNew(db, storeName, "readAll");
  return data;
}

let count = await indexDBNew(db, storeName, "count");
let isError = false;

while(count > 0) {
  let data = await fetchIndexDB();

  for (const _data of data) {

    if(!_data.image_buff){
       await indexDBNew(db, storeName, "delete", "null", _data.uuid);
       continue;
    }

    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-version": REACT_APP_VERSION,
          "x-origin": "2",
          "x-key": REACT_APP_API_KEY,
          "x-platform": "1",
          "x-device-id": identity,
          "x-auth": token,
        },
        body: JSON.stringify({
          feature_id: _data.featureId,
          image: _data.image_buff,
          uuid: _data.uuid,
          latitude: location.latitude,
          longitude: location.longitude
        }),
      };

      const res = await fetch(\`\${REACT_APP_API_URL}/trainings\`, options);
      const response = await res.json();
      if(response && response.code==="success") {
        await indexDBNew(db, storeName, "delete", "null", _data.uuid);
        channel.postMessage({
          type: 'success', 
          message: 'data successfully posted'
        });
      }
    } catch (error) {
      console.error(\`An error occurred while processing item with uuid \${_data.uuid}: \${error}\`);
      const errorMessage = \`An error occurred: \${error.message}\`;
       channel.postMessage({
        type: 'error',
        message: \`An error occurred: \${error.message}\`,
      });
    isError = true;
    break;
    } 
  };

  if (isError) {
  break;
}
  count = await indexDBNew(db, storeName, "count");
}
    return null;
  })(configs);
`
);

postIndexDBdata().catch((error) => console.error("Error executing postIndexDBdata:", error));

export default postIndexDBdata;
