import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, CardMedia, TextField, Typography } from "@mui/material";
import Alerts from "components/Alerts/Alerts";
import STATUSES from "data/constants/STATUSES";
import LinearProgress from "components/Loader/Loader";
import LoginIcon from "assets/images/login.png";

function Login(props) {
  const { status, errorMessage, clearError, onLogin } = props;
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (value, type) => {
    setInputErrorMessage("");
    switch (type) {
      case "username":
        setUserName(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = () => {
    if (!username || !password) {
      setInputErrorMessage("Fields cannot be empty");
      return;
    }
    const body = {
      username,
      password,
    };
    onLogin(body);
  };
  const handleCloseError = () => {
    clearError();
  };

  return (
    <>
      <Box>
        <LinearProgress toggle={status === STATUSES.LOADING} />
        <Alerts
          alertType="error"
          toggle={status === STATUSES.ERROR}
          content={errorMessage}
          handleCloseError={handleCloseError}
        />
      </Box>
      <Box sx={{ height: "100%" }} className="flex-container-column justify-content-between">
        <Box sx={{ width: { xs: "50%", lg: "20%" }, marginTop: { xs: "10%", lg: "2%" } }}>
          <CardMedia component="img" alt="login-icon" image={LoginIcon} />
        </Box>
        <Box
          className="flex-container-column justify-content-between width-100"
          sx={{
            height: { xs: "20%", lg: "20%" },
            width: {
              xs: "85%",
              lg: "40%",
            },
            marginBottom: { xs: "20%", lg: "2%" },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: "start",
              width: { xs: "inherit", lg: "95%" },
              textTransform: "uppercase",
            }}
          >
            User verification
          </Typography>
          <TextField
            error={!!inputErrorMessage}
            fullWidth
            onChange={(e) => handleChange(e.target.value, "username")}
            value={username}
            helperText={inputErrorMessage}
            placeholder="Username"
            variant="outlined"
            sx={{ p: 1 }}
          />
          <TextField
            error={!!inputErrorMessage}
            fullWidth
            onChange={(e) => handleChange(e.target.value, "password")}
            value={password}
            placeholder="Password"
            type="password"
            variant="outlined"
            sx={{ p: 1 }}
          />
        </Box>
        <Box sx={{ marginBottom: { xs: "15%", lg: "12%" } }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={(theme) => ({
              background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary[900]}  100%)`,
            })}
          >
            <Typography variant="subtitle1"> Login</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}

Login.defaultProps = {
  errorMessage: null,
};

Login.propTypes = {
  status: PropTypes.oneOf(["idle", "loading", "error"]).isRequired,
  errorMessage: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default Login;
