/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";
import STATUSES from "data/constants/STATUSES";
import cookiesManipulator from "services/browserStorage/cookies";

import { loginUser, logoutUser, getUserLocation } from "store/actions/auths";

const initialState = {
  currentRequestId: undefined,
  status: STATUSES.IDLE,
  errorMessage: null,
  userData: cookiesManipulator.getAuth().user,
  session: {
    token: cookiesManipulator.getAuth().token,
    expiry: cookiesManipulator.getAuth().expiry,
  },
  location: {
    latitude: null,
    longitude: null,
  },
};

export const authSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    clearAuthErrorState(state) {
      state.status = STATUSES.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { session, user } = action.payload;

          state.status = STATUSES.IDLE;
          state.session.token = session.token;
          state.session.expiry = session.expiry;
          state.userData = user;
          state.currentRequestId = undefined;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Logging out ####################
      .addCase(logoutUser.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.session = {
            token: null,
            expiry: null,
          };
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(logoutUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      .addCase(getUserLocation.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUserLocation.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { latitude, longitude } = action.payload;
          state.status = STATUSES.IDLE;
          state.location.latitude = latitude;
          state.location.longitude = longitude;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getUserLocation.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearAuthErrorState } = authSlice.actions;

export default authSlice.reducer;
