/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";
import cookiesManipulator from "services/browserStorage/cookies";

const uri = "trainings";

export const fetchMake = createAsyncThunk(
  `${uri}/fetchMake`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`meta`, "GET");
      if (response.code === "success") {
        return response.data.metadata.makes;
      }
      // We got validation errors, const's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const fetchModel = createAsyncThunk(
  `${uri}/fetchModel`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const { makeId } = requestBody;
    try {
      const response = await requestApi(`features?make_id=${makeId}`, "GET");
      if (response.code === "success") {
        response.data.features = response.data.features.filter((feature) => feature.active === 1);
        const models = [];
        const modelIds = Array.from(
          new Set(response.data.features.map((feature) => feature.model_id))
        );

        modelIds.forEach((modelId) => {
          const model = {
            model_id: modelId,
            model: response.data.features.find((feature) => feature.model_id === modelId).model,
          };

          models.push(model);
        });

        return models;
      }
      // We got validation errors, const's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const fetchVariant = createAsyncThunk(
  `${uri}/fetchVariant`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const { makeId, modelId } = requestBody;
    try {
      const response = await requestApi(`features?make_id=${makeId}&model_id=${modelId}`, "GET");

      if (response.code === "success") {
        const variantIds = Array.from(
          new Set(
            response.data.features
              .filter(
                (feature) =>
                  feature.make_id === Number(makeId) && feature.model_id === Number(modelId)
              )
              .map((feature) => feature.variant_id)
          )
        ).sort();

        const variants = [];
        variantIds.forEach((variantId) => {
          const variant = {
            variant_id: variantId,
            variant: response.data.features.find((feature) => feature.variant_id === variantId)
              .variant,
          };

          variants.push(variant);
        });

        return variants;
      }
      // We got validation errors, const's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const fetchPart = createAsyncThunk(
  `${uri}/fetchPart`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const { makeId, modelId, variantId } = requestBody;
    try {
      const response = await requestApi(
        `features?make_id=${makeId}&model_id=${modelId}&variant_id=${variantId}`,
        "GET"
      );
      if (response.code === "success") {
        response.data.features = response.data.features.filter((feature) => feature.active === 1);
        const partIds = Array.from(
          new Set(
            response.data.features
              .filter(
                (feature) =>
                  feature.make_id === Number(makeId) &&
                  feature.model_id === Number(modelId) &&
                  feature.variant_id === Number(variantId)
              )
              .map((feature) => feature.part_id)
          )
        );

        const parts = [];

        partIds.forEach((partId) => {
          const part = {
            feature_id: response.data.features.find((feature) => feature.part_id === partId).id,
            model: response.data.features.find(
              (feature) =>
                feature.make_id === Number(makeId) && feature.model_id === Number(modelId)
            ).model,
            part_id: partId,
            part: response.data.features.find((feature) => feature.part_id === partId).part,
          };

          parts.push(part);
        });

        return parts;
      }
      // We got validation errors, const's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const fetchFeature = createAsyncThunk(
  `${uri}/fetchFeature`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }
    const { makeId, modelId, variantId, partId } = requestBody;
    try {
      const response = await requestApi(
        `${uri}/stats?make_id=${makeId}&model_id=${modelId}&variant_id=${variantId}&part_id=${partId}`,
        "GET"
      );
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, const's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
